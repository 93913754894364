import { extendTheme } from '@chakra-ui/react'
import { theme } from '@chakra-ui/pro-theme'

const customTheme = {
  colors: {
    primary: { ...theme.colors, brand: theme.colors.blue }
  },
  ...theme
}

export default extendTheme(customTheme)
